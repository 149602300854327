<template>
  <div class="container">
    <el-container style="display: -webkit-box;">
      <el-header>
        <div style="width: 198px;font-size: 40px;text-align: center;color: rgb(255, 113, 0);border-right: 2px solid rgb(225, 225, 225);">猿人创新</div>
        <!-- <img class="logo" src="../assets//image//logo.png" alt=""> -->
        <ul class="submenu">
          <li class="submenu-li" v-for="(item,index) in menus" @click="changesubmenu(item,index,'sub')" :key="index" :class="[{'is-active':index==activeIndex}]">
            <router-link style="display: block;height: 100%;" :to="item.route">
              {{item.name}}
            </router-link>
          </li>
        </ul>
        <div class="logout">
          <el-select v-model="regionInfo" @change="regionChange" placeholder="切换区域">
            <el-option
              v-for="item in regionList"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          &nbsp; | &nbsp;
          <span @click="updateAdminer" style="color:rgb(255, 0, 0);cursor:pointer">{{user}}</span>
          &nbsp; | &nbsp;
          <span @click="logout" style="color:white;cursor:pointer;">注销</span>
        </div>
      </el-header>
      <el-container>
        <el-aside width="200px">

          <el-menu :default-active="activeAsideIndex+''" @click="changeasidemenu(index)" v-for="(item,index) in asidemenu" :key="index+''"  text-color="#fff" active-text-color="#ffd04b" >

            <component class="menu-item" :class="[{'is-active':index==activeAsideIndex}]" :uniqueOpened="true" v-if="item.children&&item.children.length>0" :is="'el-submenu'" :index="item.route">
              <template #title>
                <i class="el-icon-menu"></i>
                <span>{{item.name}}</span>
              </template>
              <el-menu-item @click="ThirdMenu" v-for="(v,i) in item.children" :key="v.route+i" :index="v.route">
                <router-link style="display: block;width: 100%;height: 100%;" :to="v.route">
                  <i class="el-icon-location"></i>
                  {{v.name}}
                </router-link>
              </el-menu-item>
            </component>
            <el-menu-item :class="[{'is-active':index==activeAsideIndex}]" :index="item.route" v-else>
              <router-link style="display: block;width: 100%;height: 100%;" :to="item.route">
                <i class="el-icon-menu"></i>
                {{item.name}}
              </router-link>
            </el-menu-item>
          </el-menu>

        </el-aside>
          <el-main>
            <d-breade ref="bread"></d-breade>
            <el-card shadow="always" style="margin-top:10px">
              <router-view></router-view>
            </el-card>
          </el-main>
          <!-- <el-footer>Footer</el-footer> -->
      </el-container>
    </el-container>

    <el-dialog title="修改个人信息" v-model="dialogFormVisible" width="600px">
      <el-form :model="adminer">
        <el-form-item label="权限" label-width="100px">
          <el-input v-model="adminer.role" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号" label-width="100px">
          <el-input v-model="adminer.account" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="名称" label-width="100px">
          <el-input v-model="adminer.name" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="电话" label-width="100px">
          <el-input v-model="adminer.phone" autocomplete="off" ></el-input>
        </el-form-item>
        <el-form-item label="密码" label-width="100px">
          <el-input v-model="password" autocomplete="off" placeholder="不输入则不修改密码"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdminer">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import dBreade from '../components/d-bread.vue'
export default {
  name: '',
  mixins: [],
  components: {
    dBreade
  },
  props: {},
  data () {
    return {
      menus:[],
      activeIndex: 1,
      asidemenu: [],
      dialogFormVisible: false,
      adminer: {
        "id": 0,
        "name": "",
        "phone": "",
        "account": "",
        "role": ""
      },
      password: '',
      activeAsideIndex: 0,
      user: '',
      regionList: [],
      regionInfo: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    regionChange () {
      localStorage.setItem('region_change',this.regionInfo)
      window.location.reload()
      // this.GetAllMenu(true)
    },
    GetAllMenu (regionChange) {
      this.api.AdminMenu({}).then(res => {
         if (res.data.code == 200) {
          localStorage.setItem('allmenu', JSON.stringify(res.data.data))
          if (regionChange) {
            localStorage.setItem('this_submenu', 0)
            localStorage.setItem('this_asidemenu', 0)
            this.changesubmenu(this.menus[localStorage.this_submenu?localStorage.this_submenu:0],localStorage.this_submenu?localStorage.this_submenu:0)
            this.changeasidemenu(0,'',true)
          } else {
            this.$router.push(this.asidemenu[this.activeAsideIndex].route)
            this.$router.push({path: '/index'}).catch(err => {
              console.log(err)
            })
          }
          return
        }
        this.$message.error('获取菜单失败' + res.data.msg)
      })
    },
    // 一个修改
    changesubmenu (item, index,which) {
      sessionStorage.removeItem("currentPage")
      if (which == 'sub') {
        localStorage.setItem('this_asidemenu', 0)
      }
      this.activeAsideIndex = 0
      this.activeIndex = index
      this.asidemenu = item.children
      // 保存现在点击的菜单
      localStorage.setItem('this_submenu', index)
    },
    updateAdminer () {
      // 先获取用户信息
      this.dialogFormVisible = !this.dialogFormVisible
      this.api.UserDetail({id:JSON.parse(localStorage.getItem('user_info'))["id"]}).then(res => {
        if (res.data.code == 200) {
          this.adminer=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    submitAdminer () {
      this.api.UserUpdate({
        id: +this.form.id,
        account: this.form.account,
        password: md5(this.form.password),
        name: this.form.name,
        status: +this.form.status
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('修改成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error('修改失败' + res.data.msg)
      })
    },
    logout () {
      // let timestamp = new Date().getTime()
      // let header = {
      //   request_id: timestamp,
      //   client_time: parseInt(timestamp/1000),
      //   'CA-KEYS': "client_time,request_id",
      // }
      // header.signature = this.signature(timestamp,param,header,'get')
      // let _this = this
      this.$confirm('确定注销登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      //   this.$axios.get('/admin/v1/login/out',{
      //   params: '',
      //   headers: header
      // }).then(res => {
      //     if (res.data.code === 200) {
      //       _this.$message({
      //         type: 'success',
      //         message: '注销成功!'
      //       });
      //       return
      //     }
      //     this.$message.error('注销失败！')
      //   })
        this.$router.push('/login')
        localStorage.clear()
      }).catch(() => {
        _this.$message({
          type: 'info',
          message: '已取消注销'
        });
      })
    },
    changeasidemenu (index,item,changeRegion) {
      sessionStorage.removeItem("currentPage")
      this.activeAsideIndex = index
      localStorage.setItem('this_asidemenu', this.activeAsideIndex)
      if (this.asidemenu && (this.$router.currentRoute._value.fullPath == '/index' || changeRegion)) {
        this.$router.push(this.asidemenu[index].route)
      }
    },
    ThirdMenu () {
      sessionStorage.removeItem("currentPage")
    }
  },
  filters: {},
  mounted () {
    if (!localStorage.is_login) {
      this.$router.push('/login')
      return
    }
    this.menus = JSON.parse(localStorage.getItem('allmenu'))
    this.regionList = JSON.parse(localStorage.getItem('user_info')).region_list
    this.regionInfo = localStorage.getItem('region_change')
    // 刷新保持原路由
    this.user = JSON.parse(localStorage.getItem('user_info'))["name"]
    this.changesubmenu(this.menus[localStorage.this_submenu?localStorage.this_submenu:0],localStorage.this_submenu?localStorage.this_submenu:0)
    this.changeasidemenu(localStorage.this_asidemenu?localStorage.this_asidemenu:0,'mounted')
  },
  created () {
  }
}
</script>
<style>
.el-menu, .el-menu-vertical-demo{
  background-color: rgb(33, 41, 57);
}
.el-menu{
  border-right: none
}
</style>
<style scoped>
.logout .el-select {
  width: 150px
}
.container
.el-container {
  height: 100%;
}
.el-header {
  padding: 0;
  display: flex;
  background-color: rgb(33, 41, 57);
  line-height: 60px;
}
.logo {
  border-bottom: 2px solid rgb(220, 220, 220);
}
.submenu {
  font-size: 14px;
  display: flex;
  color: white;
  flex: 1;
  /* background-color: rgb(225, 225, 225); */
}
.submenu-li {
  padding: 0 20px;
}
.is-active {
  background-color: rgb(189, 100, 0);
  border-bottom:none;
}
.is-active .router-link-active {
  color: #fff;
}
.el-aside {
  background-color: rgb(33,41,57);
  border-right: 2px solid rgb(220, 220, 220);
}
.logout {
  /* background-color: rgb(225, 225, 225); */
  padding-right: 90px;
  font-size: 14px;
}
/* .asidemenu-li { */
  /* border-top: 1px solid rgb(235, 235, 235);
  border-bottom: 1px solid rgb(235, 235, 235); */
/* } */
.asidemenu-li a {
  height: 40px;
  display: block;
  text-overflow: ellipsis;
  line-height: 40px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.asideli-active {
  background-color: rgb(189, 100, 0);
}
.el-card {
  margin-top: 10px;
}
.el-footer {
  background-color: #fff;
  border-top: 2px solid rgb(220, 220, 220);
}
</style>
